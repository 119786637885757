import React, { ReactNode } from "react";
import { PtwRoutePath } from "./_.route-path";

// Aunthentication
const PtwNotFoundError = React.lazy(
  () => import("../features/errors/PtwNotFoundError"),
);
const PtwInternalServerError = React.lazy(
  () => import("../features/errors/PtwInternalServerError"),
);

// Dashboard
const PtwDashboard = React.lazy(
  () => import("../features/dashboard/view/Dashboard"),
);
// Dashboard Approver
const PtwDashboardApprover = React.lazy(
  () => import("../features/dashboard/view/DashboardApprover"),
);
// Dashboard AuxPolice
const PtwDashboardAuxPolice = React.lazy(
  () => import("../features/dashboard/view/DashboardAuxPolice"),
);

// Profile
const PtwProfile = React.lazy(
  () => import("../features/profile/view/PtwProfile"),
);
const PtwProfileAccount = React.lazy(
  () => import("../features/profile/view/components/PtwProfile.AccountPass"),
);

// Application Form
const Section1 = React.lazy(
  () => import("../features/new-permit/view/Section1"),
);
const Section1Edit = React.lazy(
  () => import("../features/new-permit/view/Section1-Edit"),
);
const Section2 = React.lazy(
  () => import("../features/new-permit/view/Section2"),
);
const Section3 = React.lazy(
  () => import("../features/new-permit/view/Section3"),
);
const Section4 = React.lazy(
  () => import("../features/new-permit/view/Section4"),
);
const Section5 = React.lazy(
  () => import("../features/new-permit/view/Section5"),
);

// Draft Permit
const DraftPermit = React.lazy(
  () => import("../features/draft-permit/views/DraftPermit"),
);

const DraftPermitbyId = React.lazy(
  () => import("../features/draft-permit/views/DraftPermitById"),
);

const DraftPermitSection2 = React.lazy(
  () => import("../features/draft-permit/views/DraftPermitSection2"),
);
const DraftPermitSection3 = React.lazy(
  () => import("../features/draft-permit/views/DraftPermitSection3"),
);
const DraftPermitSection4 = React.lazy(
  () => import("../features/draft-permit/views/DraftPermitSection4"),
);
const DraftPermitSection5 = React.lazy(
  () => import("../features/draft-permit/views/DraftPermitSection5"),
);
const PendingPermit = React.lazy(
  () => import("../features/pending-permit/views/pendingPermit"),
);

const PendingPermitbyId = React.lazy(
  () => import("../features/pending-permit/views/PendingPermit.ById"),
);

// Active Permit
const ActivePermit = React.lazy(
  () => import("../features/active-permit/views/ActivePermit"),
);
const ActivePermitByIdDetails = React.lazy(
  () => import("../features/active-permit/views/ActivePermitByIdDetails"),
);
const PtwConfinedSpaceSafetyForm = React.lazy(
  () => import("../features/active-permit/views/PtwConfinedSpaceSafetyForm"),
);
const ActivePermitById = React.lazy(
  () => import("../features/active-permit/views/ActivePermitById"),
);
const PtwPrintActivePermit = React.lazy(
  () => import("../features/active-permit/views/PtwPrintActivePermit"),
);

// History User
const ApplicationHistory = React.lazy(
  () =>
    import(
      "../features/history-user/application-history/views/ApplicationHistory"
    ),
);
const ApplicationHistoryById = React.lazy(
  () =>
    import(
      "../features/history-user/application-history/views/ApplicationHistoryById"
    ),
);
const ApplicationHistoryByIdDetails = React.lazy(
  () =>
    import(
      "../features/history-user/application-history/views/ApplicationHistoryByIdDetails"
    ),
);
const PtwApplicationHistoryConfinedSpaceSafetyForm = React.lazy(
  () =>
    import(
      "../features/history-user/application-history/views/PtwConfinedSpaceSafetyForm"
    ),
);
const PtwPrintApplicationHistory = React.lazy(
  () =>
    import(
      "../features/history-user/application-history/views/PtwPrintApplicationHistory"
    ),
);

const PaymentHistory = React.lazy(
  () => import("../features/history-user/payment-history/views/PaymentHistory"),
);
const PtwPrintReceiptPaymentHistory = React.lazy(
  () =>
    import(
      "../features/history-user/payment-history/views/components/PtwPrintReceiptPaymentHistory"
    ),
);

// Manage
const AdminManagemet = React.lazy(
  () => import("../features/manage/admin/ManageAdmin"),
);
const ProjectOwnerManagement = React.lazy(
  () => import("../features/manage/projectOwner/views/ManagePo"),
);
const AreaOwnerManagement = React.lazy(
  () => import("../features/manage/areaOwner/views/ManageAo"),
);
const HseManagement = React.lazy(
  () => import("../features/manage/hse/views/ManageHse"),
);
const AuxPoliceManagement = React.lazy(
  () => import("../features/manage/ap/views/ManageAp"),
);
const TowManagement = React.lazy(
  () => import("../features/manage/typeOfWork/views/ManageTow"),
);
const DowManagement = React.lazy(
  () => import("../features/manage/dayOfWork/views/ManageDow"),
);
const LocationManagement = React.lazy(
  () => import("../features/manage/location/views/ManageLocation"),
);
const DepartmentManagement = React.lazy(
  () => import("../features/manage/department/views/ManageDepartment"),
);

// Auxiliary Police
const PendingPayment = React.lazy(
  () => import("../features/pending-payment/views/PendingPayment"),
);
const PendingPaymentById = React.lazy(
  () => import("../features/pending-payment/views/PendingPaymentById"),
);
const PendingPaymentSummary = React.lazy(
  () => import("../features/pending-payment/views/PendingPaymentSummary"),
);
const PendingPaymentFocSummary = React.lazy(
  () => import("../features/pending-payment/views/PendingPaymentFocSummary"),
);
const PtwPrintReceiptPendingPayment = React.lazy(
  () =>
    import("../features/pending-payment/views/PtwPrintReceiptPendingPayment"),
);

const PendingSinglePaymentSummary = React.lazy(
  () => import("../features/pending-payment/views/PendingSinglePaymentSummary"),
);
const PendingSinglePaymentFocSummary = React.lazy(
  () =>
    import("../features/pending-payment/views/PendingSinglePaymentFocSummary"),
);
const PtwPrintReceiptPendingSinglePayment = React.lazy(
  () =>
    import(
      "../features/pending-payment/views/PtwPrintReceiptPendingSinglePayment"
    ),
);
const HistoryPermit = React.lazy(
  () => import("../features/history-permit/permit-history/views/HistoryPermit"),
);
const HistoryPermitById = React.lazy(
  () =>
    import("../features/history-permit/permit-history/views/HistoryPermitById"),
);
const HistoryPermitByIdDetails = React.lazy(
  () =>
    import(
      "../features/history-permit/permit-history/views/HistoryPermitByIdDetails"
    ),
);

const HistoryPermitAp = React.lazy(
  () => import("../features/history-permit-ap/views/HistoryPermit"),
);
const HistoryPermitDetailsSummaryAp = React.lazy(
  () =>
    import("../features/history-permit-ap/views/HistoryPermitDetailSummary"),
);
const HistoryPermitByIdAp = React.lazy(
  () => import("../features/history-permit-ap/views/HistoryPermitByIdDetails"),
);
const PtwPrintHistoryPermitAp = React.lazy(
  () => import("../features/history-permit-ap/views/PtwPrintHistoryPermit"),
);

const HistoryPermitPrintListPreview = React.lazy(
  () =>
    import(
      "../features/history-permit/permit-history/views/HistoryPermitPrintListPreview"
    ),
);
const PtwHistoryPermitConfinedSpaceSafetyForm = React.lazy(
  () =>
    import(
      "../features/history-permit/permit-history/views/PtwConfinedSpaceSafetyForm"
    ),
);
const PtwPrintHistoryPermit = React.lazy(
  () =>
    import(
      "../features/history-permit/permit-history/views/PtwPrintHistoryPermit"
    ),
);
const HistoryPayment = React.lazy(
  () =>
    import("../features/history-payment/payment-history/views/HistoryPayment"),
);
const PtwPrintReceiptHistoryPayment = React.lazy(
  () =>
    import(
      "../features/history-payment/payment-history/views/PtwPrintReceiptHistoryPayment"
    ),
);

// Auxiliary Police
const PtwSuspendForm = React.lazy(
  () => import("../features/suspend/views/PtwSuspendForm"),
);
const PtwListApplication = React.lazy(
  () => import("../features/suspend/views/PtwListApplication"),
);

const PtwListApplicationById = React.lazy(
  () => import("../features/suspend/views/PtwListApplicationById"),
);

// Active Permit Approval
const ActivePermitApprover = React.lazy(
  () => import("../features/active-permit-approver/views/ActivePermit"),
);
const ActivePermitDailyApproval = React.lazy(
  () =>
    import("../features/active-permit-approver/views/ActivePermitByIdDetails"),
);

const ActivePermitSummaryDetails = React.lazy(
  () => import("../features/active-permit-approver/views/ActivePermitById"),
);
const ActivePermitSummaryDetailsPrint = React.lazy(
  () => import("../features/active-permit-approver/views/PtwPrintActivePermit"),
);

const ActivePermitSafetyForm = React.lazy(
  () =>
    import("../features/active-permit-approver/views/ActivePermitSafetyForm"),
);

// Pending Approval PO, AO, HSE

const PtwCloseDaily = React.lazy(
  () => import("../features/pending-approval/close-daily/views/PtwCloseDaily"),
);

const PtwCloseDailyById = React.lazy(
  () =>
    import("../features/pending-approval/close-daily/views/PtwCloseDailyById"),
);
const PtwCloseDailyByIdDetails = React.lazy(
  () =>
    import(
      "../features/pending-approval/close-daily/views/PtwCloseDailyByIdDetails"
    ),
);
const PtwExtendPermit = React.lazy(
  () => import("../features/pending-approval/extend/views/PtwExtendPermit"),
);
const PtwExtendPermitById = React.lazy(
  () => import("../features/pending-approval/extend/views/PtwExtendPermitById"),
);
const PtwExtendPermitByIdDetails = React.lazy(
  () =>
    import(
      "../features/pending-approval/extend/views/PtwExtendPermitByIdDetails"
    ),
);
const PtwClosing = React.lazy(
  () => import("../features/pending-approval/closing/views/PtwClosing"),
);
const PtwClosingByIdDetails = React.lazy(
  () =>
    import(
      "../features/pending-approval/closing/views/PtwClosingPermitDetails"
    ),
);
const PtwClosingById = React.lazy(
  () => import("../features/pending-approval/closing/views/PtwClosingById"),
);
const PtwClosingReportRegister = React.lazy(
  () =>
    import(
      "../features/pending-approval/closing/views/PtwClosingReportRegister"
    ),
);
const PtwNewPermit = React.lazy(
  () => import("../features/pending-approval/new-permit/views/PtwNewPermit"),
);
const PtwNewPermitById = React.lazy(
  () =>
    import("../features/pending-approval/new-permit/views/PtwNewPermitById"),
);
const PtwCompletePermit = React.lazy(
  () => import("../features/pending-approval/complete/views/PtwCompletePermit"),
);
const PtwCompletePermitById = React.lazy(
  () =>
    import("../features/pending-approval/complete/views/PtwCompletePermitById"),
);
const PtwCompletePermitByIdDetails = React.lazy(
  () => import("../features/pending-approval/complete/views/PtwCompleteById"),
);

//Safety Briefing
const PtwSafetyBriefing = React.lazy(
  () => import("../features/safety-briefing/views/PtwSafetyBriefingHolder"),
);

export type route = {
  component?: ReactNode;
  name?: string;
  key?: string;
  path?: string;
  routes?: route[];
  ids?: string[];
  disabled?: undefined | boolean;
};

const ptwRoutes = [
  { path: PtwRoutePath.Home, name: "Home" },
  {
    path: PtwRoutePath.Dashboard,
    name: "Dashboard",
    component: PtwDashboard,
    disabled: true,
  },
  {
    path: PtwRoutePath.DashboardApprover,
    name: "Approver",
    component: PtwDashboardApprover,
  },
  {
    path: PtwRoutePath.DashboardAuxPolice,
    name: "Aux Police",
    component: PtwDashboardAuxPolice,
  },
  {
    path: PtwRoutePath.NotFoundError,
    name: "Page Not Found",
    component: PtwNotFoundError,
  },
  {
    path: PtwRoutePath.InternalServerError,
    name: "Internal Server Error",
    component: PtwInternalServerError,
  },
  // Profile
  {
    path: PtwRoutePath.Profile,
    name: "Profile",
    component: PtwProfile,
  },
  {
    path: PtwRoutePath.ProfileAccount,
    name: "Profile Account",
    ids: ["userId"],
    component: PtwProfileAccount,
  },
  // Application Form
  {
    path: PtwRoutePath.Section1,
    name: "New Permit",
    component: Section1,
  },
  {
    path: PtwRoutePath.Section1Edit,
    name: "New Permit",
    component: Section1Edit,
  },
  {
    path: PtwRoutePath.Section2,
    name: "New Permit",
    component: Section2,
  },
  {
    path: PtwRoutePath.Section3,
    name: "New Permit",
    component: Section3,
  },
  {
    path: PtwRoutePath.Section4,
    name: "New Permit",
    component: Section4,
  },
  {
    path: PtwRoutePath.Section5,
    name: "New Permit",
    component: Section5,
  },

  // Draft Permit
  {
    path: PtwRoutePath.DraftPermit,
    name: "Draft Permit",
    component: DraftPermit,
  },
  {
    path: PtwRoutePath.DraftPermitDetails,
    name: "Draft Permit Section 1",
    ids: ["draftPermitId"],
    component: DraftPermitbyId,
  },
  {
    path: PtwRoutePath.DraftPermitSection2,
    name: "Section 2",
    component: DraftPermitSection2,
  },
  {
    path: PtwRoutePath.DraftPermitSection3,
    name: "Section 3",
    component: DraftPermitSection3,
  },
  {
    path: PtwRoutePath.DraftPermitSection4,
    name: "Section 4",
    component: DraftPermitSection4,
  },
  {
    path: PtwRoutePath.DraftPermitSection5,
    name: "Section 5",
    component: DraftPermitSection5,
  },

  // Pending Permit
  {
    path: PtwRoutePath.PendingPermit,
    name: "Pending Permit",
    component: PendingPermit,
  },
  {
    path: PtwRoutePath.PendingPermitDetail,
    name: "Pending Permit Summary",
    ids: ["pendingId"],
    component: PendingPermitbyId,
  },

  // Active Permit
  {
    path: PtwRoutePath.ActivePermit,
    name: "Active Permit",
    component: ActivePermit,
  },
  {
    path: PtwRoutePath.ActivePermitSummary,
    name: "Active Permit Summary",
    ids: ["activePermitId"],
    component: ActivePermitById,
  },
  {
    path: PtwRoutePath.ActivePermitDetails,
    name: "Active Permit Details",
    ids: ["activePermitId"],
    component: ActivePermitByIdDetails,
  },
  {
    path: PtwRoutePath.ActivePermitSafetyForm,
    name: "Active Permit Confined Safety Form",
    ids: ["activePermitId"],
    component: PtwConfinedSpaceSafetyForm,
  },
  {
    path: PtwRoutePath.ActivePermitPrint,
    name: "Active Permit Print",
    component: PtwPrintActivePermit,
  },
  // History User
  {
    path: PtwRoutePath.ApplicationHistory,
    name: "Application History",
    component: ApplicationHistory,
  },
  {
    path: PtwRoutePath.ApplicationHistorySummary,
    name: "Application History Summary",
    ids: ["applicantHistoryId"],
    component: ApplicationHistoryById,
  },
  {
    path: PtwRoutePath.ApplicationHistoryDetails,
    name: "Application History Details",
    ids: ["applicantHistoryId"],
    component: ApplicationHistoryByIdDetails,
  },
  {
    path: PtwRoutePath.ApplicationHistoryPrint,
    name: "Application History Print",
    component: PtwPrintApplicationHistory,
  },
  {
    path: PtwRoutePath.ApplicationHistorySafetyForm,
    name: "Application History Confined Safety Form",
    ids: ["applicantHistoryId"],
    component: PtwApplicationHistoryConfinedSpaceSafetyForm,
  },
  {
    path: PtwRoutePath.PaymentHistory,
    name: "Payment History",
    component: PaymentHistory,
  },
  {
    path: PtwRoutePath.PaymentHistoryPrintReciept,
    name: "Payment History Reciept",
    component: PtwPrintReceiptPaymentHistory,
  },
  // Manage
  {
    path: PtwRoutePath.AdminManagement,
    name: "Admin Management",
    component: AdminManagemet,
  },
  {
    path: PtwRoutePath.PoManagement,
    name: "Project Owner Management",
    component: ProjectOwnerManagement,
  },
  {
    path: PtwRoutePath.AoManagement,
    name: "Area Owner Management",
    component: AreaOwnerManagement,
  },
  {
    path: PtwRoutePath.HseManagement,
    name: "Health, Safety and Environment Management",
    component: HseManagement,
  },
  {
    path: PtwRoutePath.ApManagement,
    name: "Auxiliary Police Management",
    component: AuxPoliceManagement,
  },
  {
    path: PtwRoutePath.TowManagement,
    name: "Type of Work",
    component: TowManagement,
  },
  {
    path: PtwRoutePath.DowManagement,
    name: "Day of Work",
    component: DowManagement,
  },
  {
    path: PtwRoutePath.LocationManagement,
    name: "Location",
    component: LocationManagement,
  },
  {
    path: PtwRoutePath.DepartmentManagement,
    name: "Department",
    component: DepartmentManagement,
  },

  // Auxiliary Police
  {
    path: PtwRoutePath.PendingPayment,
    name: "Pending Payment",
    component: PendingPayment,
  },
  {
    path: PtwRoutePath.PendingPaymentDetails,
    name: "Pending Payment",
    ids: ["pendingPaymentId"],
    component: PendingPaymentById,
  },
  {
    path: PtwRoutePath.PendingPaymentSummary,
    name: "Payment Summary",
    component: PendingPaymentSummary,
  },
  {
    path: PtwRoutePath.PendingPaymentFocSummary,
    name: "Payment Summary",
    component: PendingPaymentFocSummary,
  },
  {
    path: PtwRoutePath.PendingPaymentPrintReciept,
    name: "Pending Payment Reciept",
    component: PtwPrintReceiptPendingPayment,
  },

  {
    path: PtwRoutePath.PendingSinglePaymentSummary,
    name: "Payment Summary",
    component: PendingSinglePaymentSummary,
  },
  {
    path: PtwRoutePath.PendingSinglePaymentFocSummary,
    name: "Payment Summary",
    component: PendingSinglePaymentFocSummary,
  },
  {
    path: PtwRoutePath.PendingSinglePaymentPrintReciept,
    name: "Pending Payment Reciept",
    component: PtwPrintReceiptPendingSinglePayment,
  },

  {
    path: PtwRoutePath.HistoryPermitAp,
    name: "History Permit",
    component: HistoryPermitAp,
  },
  {
    path: PtwRoutePath.HistoryPermitSummaryAp,
    name: "History Permit Summary",
    ids: ["historyPermitId"],
    component: HistoryPermitDetailsSummaryAp,
  },
  {
    path: PtwRoutePath.HistoryPermitDetailsAp,
    name: "History Permit Details",
    ids: ["historyPermitId"],
    component: HistoryPermitByIdAp,
  },
  {
    path: PtwRoutePath.HistoryPermitPrintAp,
    name: " History Permit Print",
    component: PtwPrintHistoryPermitAp,
  },

  {
    path: PtwRoutePath.HistoryPermit,
    name: "History Permit",
    component: HistoryPermit,
  },
  {
    path: PtwRoutePath.HistoryPermitSummary,
    name: "History Permit Summary",
    ids: ["historyPermitId"],
    component: HistoryPermitById,
  },
  {
    path: PtwRoutePath.HistoryPermitDetails,
    name: "History Permit Details",
    ids: ["historyPermitId"],
    component: HistoryPermitByIdDetails,
  },
  {
    path: PtwRoutePath.HistoryPermitPrint,
    name: " History Permit Print",
    component: PtwPrintHistoryPermit,
  },
  {
    path: PtwRoutePath.HistoryPermitSafetyForm,
    name: "History Permit Confined Safety Form",
    ids: ["historyPermitId"],
    component: PtwHistoryPermitConfinedSpaceSafetyForm,
  },
  {
    path: PtwRoutePath.HistoryPermitPrintListPreview,
    name: " History Permit Print",
    component: HistoryPermitPrintListPreview,
  },

  {
    path: PtwRoutePath.HistoryPayment,
    name: "History Payment",
    component: HistoryPayment,
  },
  {
    path: PtwRoutePath.HistoryPaymentPrintReciept,
    name: "History Payment Reciept",
    component: PtwPrintReceiptHistoryPayment,
  },

  // Suspend - Approver
  {
    path: PtwRoutePath.SuspendForm,
    name: "Suspend",
    component: PtwSuspendForm,
  },
  {
    path: PtwRoutePath.ListApplication,
    name: "List Application",
    ids: ["locationId", "suspendId"],
    component: PtwListApplication,
  },
  {
    path: PtwRoutePath.ListApplicationSummary,
    name: "Application Summary",
    ids: ["locationId", "suspendId", "permitId"],
    component: PtwListApplicationById,
  },

  // Active Permit Approver
  {
    path: PtwRoutePath.ActivePermitApprover,
    name: "Active Permit",
    component: ActivePermitApprover,
  },
  {
    path: PtwRoutePath.ActivePermitApproverSummary,
    name: "Active Permit Summary",
    ids: ["activePermitId"],
    component: ActivePermitSummaryDetails,
  },
  {
    path: PtwRoutePath.ActivePermitApproverDetails,
    name: "Active Permit Details",
    ids: ["activePermitId"],
    component: ActivePermitDailyApproval,
  },
  {
    path: PtwRoutePath.ActivePermitApproverPrint,
    name: "Active Permit Print",
    component: ActivePermitSummaryDetailsPrint,
  },

  {
    path: PtwRoutePath.ActivePermitApproverSafetyForm,
    name: "Active Permit Confined Safety Form",
    component: ActivePermitSafetyForm,
  },

  // Pending Approval PO, AO, HSE
  {
    path: PtwRoutePath.NewPermit,
    name: "New Permit",
    component: PtwNewPermit,
  },
  {
    path: PtwRoutePath.NewPermitSummary,
    name: "New Permit Summary",
    ids: ["newPermitId"],
    component: PtwNewPermitById,
  },
  {
    path: PtwRoutePath.Extend,
    name: "Extend Permit",
    ids: ["extendPermitId"],
    component: PtwExtendPermit,
  },
  {
    path: PtwRoutePath.ExtendPermitSummary,
    name: "Extend Permit Summary",
    ids: ["extendPermitId"],
    component: PtwExtendPermitById,
  },
  {
    path: PtwRoutePath.ExtendDetails,
    name: "Extend Permit",
    ids: ["extendPermitId"],
    component: PtwExtendPermitByIdDetails,
  },
  {
    path: PtwRoutePath.CloseDaily,
    name: "Close Daily",
    ids: ["closeDailyId"],
    component: PtwCloseDaily,
  },
  {
    path: PtwRoutePath.CloseDailySummary,
    name: "Close Daily Summary",
    ids: ["closeDailyId"],
    component: PtwCloseDailyById,
  },
  {
    path: PtwRoutePath.CloseDailyDetails,
    name: "Close Daily",
    ids: ["closeDailyId"],
    component: PtwCloseDailyByIdDetails,
  },
  {
    path: PtwRoutePath.Closing,
    name: "Closing Permit",
    ids: ["closingId"],
    component: PtwClosing,
  },
  {
    path: PtwRoutePath.ClosingDetails,
    name: "Closing Permit",
    ids: ["closingId"],
    component: PtwClosingByIdDetails,
  },
  {
    path: PtwRoutePath.ClosingSummary,
    name: "Closing Permit Summary",
    ids: ["closingId"],
    component: PtwClosingById,
  },
  {
    path: PtwRoutePath.ClosingReportRegister,
    name: "Closing Report Register",
    ids: ["closingId"],
    component: PtwClosingReportRegister,
  },
  {
    path: PtwRoutePath.CompletePermit,
    name: "Complete Permit",
    component: PtwCompletePermit,
  },
  {
    path: PtwRoutePath.CompletePermitDetails,
    name: "Complete Permit",
    ids: ["completePermitId"],
    component: PtwCompletePermitById,
  },
  {
    path: PtwRoutePath.CompletePermitSummary,
    name: "Complete Permit Summary",
    ids: ["completePermitId"],
    component: PtwCompletePermitByIdDetails,
  },

  //Safety Briefing
  {
    path: PtwRoutePath.SafetyBriefing,
    name: "Safety Briefing",
    component: PtwSafetyBriefing,
  },
];

export default ptwRoutes;
